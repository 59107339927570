import React from "react"
import { NewsLayout } from "sections/News/"
import useNewsData from "shared/Hooks/News/useNewsData"
import { TextBlock } from "src/sections/News/NewsLayout/TextBlock";
import { NEWS_61 } from "types/News"
import { Images } from "../../sections/News/NewsLayout/Images";

const Article = () => {
  const data = useNewsData(NEWS_61);

  return (
    <NewsLayout data={data}>
      <Images data={data.images.first}/>
      <TextBlock data={data.texts.first}/>
    </NewsLayout>
  )
}

export default Article;
